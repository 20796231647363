import React, {useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Async from "react-code-splitting";
import { handleMobileOrDesktop } from "../redux/actions/mainActions";
import withStyles from "@material-ui/core/styles/withStyles";
import menuStyle from "../assets/jss/material-dashboard-react/layouts/menuStyle.jsx";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import ScrollToTop from "react-scroll-to-top";




const Main = () => <Async load={import("../content/Main.jsx")} />;
const InsuranceTypes = () => < Async load={import("../components/AC-StaticPages/InsuranceTypes/InsuraceTypes")} />;
const News = () => < Async load={import("../components/AC-StaticPages/News/News")} />;
const Courses = () => < Async load={import("../components/AC-StaticPages/Courses/Courses")} />;
const AboutUs = () => < Async load={import("../components/AC-StaticPages/AboutUs/AboutUs")} />;
const Placements = () => < Async load={import("../components/AC-StaticPages/Placements/Placements")} />;
const Affilication = () => < Async load={import("../components/AC-StaticPages/Affilication/Affilication")} />;
const ContactUs = () => < Async load={import("../components/AC-StaticPages/ContactUs/ContactUs")} />;
const Admissions = () => < Async load={import("../components/AC-StaticPages/Admissions/Admissions")} />;
const FinancialAssistance = () => < Async load={import("../components/AC-StaticPages/FinancialAssistance/FinancialAssistance")} />;
const Mentors = () => < Async load={import("../components/AC-StaticPages/Mentors/Mentors")} />;
const Scholarships = () => < Async load={import("../components/AC-StaticPages/Scholarships/Scholarships")} />;
const Blog = () => < Async load={import("../components/AC-StaticPages/Blog/Blog")} />;
const Alumni = () => < Async load={import("../components/AC-StaticPages/Alumni/Alumni")} />;
const Workshop = () => < Async load={import("../components/AC-StaticPages/Workshop/Workshop")} />;
const Accelerator = () => < Async load={import("../components/AC-StaticPages/Accelerator/Accelerator")} />;
const Facilities = () => < Async load={import("../components/AC-StaticPages/Facilities/Facilities")} />;
const Blogdetail = () => < Async load={import("../components/AC-StaticPages/BlogDetails/Blogdetail.js")} />;
const mobilecounter = () => < Async load={import("../components/AC-StaticPages/counter/counter.js")} />;
const Datepicker = () => < Async load={import("../components/AC-StaticPages/Datepicker/Datepicker.js")} />;
const ComingSoon = () => < Async load={import("../components/AC-StaticPages/ComingSoon/ComingSoon")} />;




const StudentShowcase = () => < Async load={import("../components/AC-StaticPages/StudentShowcase/StudentShowcase")} />;







  const DiplomaAndAdvancedDiplomaCourses = () => < Async load={import("../components/AC-StaticPages/landingpage/DiplomaAndAdvancedDiplomaCourses")} />;
  const DiplomaAndAdvancedDiplomaCourses2 = () => < Async load={import("../components/AC-StaticPages/landingpage/DiplomaAndAdvancedDiplomaCourses2")} />;
   const DiplomaAndAdvancedDiplomaGamingCourses = () => < Async load={import("../components/AC-StaticPages/landingpage/DiplomaAndAdvancedDiplomaGamingCourses")} />;
  const DiplomaAndAdvancedDiplomaGamingCourses2 = () => < Async load={import("../components/AC-StaticPages/landingpage/DiplomaAndAdvancedDiplomaGamingCourses2")} />;
  
  const AllDiplomaAndAdvancedDiplomaGamingCourses = () => < Async load={import("../components/AC-StaticPages/landingpage/AllDiplomaAndAdvancedDiplomaGamingCourses.js")}/>
const BachelorsInComputerScienceGameDevelopment = () => < Async load={import("../components/AC-StaticPages/BachelorsInComputerScienceGameDevelopment/BachelorsInComputerScienceGameDevelopment")} />;
const GameArtDesign = () => < Async load={import("../components/AC-StaticPages/GameArtDesign/GameArtDesign")} />;
const AugmentedRealityAndVirtualReality = () => < Async load={import("../components/AC-StaticPages/AugmentedRealityAndVirtualReality/AugmentedRealityAndVirtualReality")} />;

const MScGameTechnology = () => < Async load={import("../components/AC-StaticPages/MScGameTechnology/MScGameTechnology")} />;
const Applicationform = () => < Async load={import("../components/AC-StaticPages/Applicationform/Applicationform")} />;
const AdvancedDiplomaInGameDevelopment = () => < Async load={import("../components/AC-StaticPages/AdvancedDiplomaInGameDevelopment/AdvancedDiplomaInGameDevelopment")} />;

const AdvancedDiplomain3DGameArtDigitalSculpting = () => < Async load={import("../components/AC-StaticPages/AdvancedDiplomain3DGameArtDigitalSculpting/AdvancedDiplomain3DGameArtDigitalSculpting")} />;

const DiplomaIn3dEnvironmentArtCreationForGames = () => < Async load={import("../components/AC-StaticPages/DiplomaIn3dEnvironmentArtCreationForGames/DiplomaIn3dEnvironmentArtCreationForGames")} />;

const DiplomainGameDesignProduction = () => < Async load={import("../components/AC-StaticPages/DiplomainGameDesignProduction/DiplomainGameDesignProduction")} />;

const DiplomaInGameDevelopmentWithUnity = () => < Async load={import("../components/AC-StaticPages/DiplomaInGameDevelopmentWithUnity/DiplomaInGameDevelopmentWithUnity")} />;

const AdvancedDiplomainTraditionalDigitalConceptArt = () => < Async load={import("../components/AC-StaticPages/AdvancedDiplomainTraditionalDigitalConceptArt/AdvancedDiplomainTraditionalDigitalConceptArt")} />;





const MenuCore = () => <Async load={import("./MenuCore.jsx")} />;
const Footer = () => <Async load={import("../components/AC-Footer/Footer")} />;


const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
    },
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


function Menu(props) {
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let isMobile;
  const mobileSize = 768;
  React.useEffect(() => {
    if (width > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop(isMobile));
  }, [width]);

  

  // const isMobileState = useSelector(
  //   state => state.mainReducer.isMobile,
  //   shallowEqual
  // );

  const toolbarStyle = {
    minHeight: '80px',
  };

  useEffect(() => {
    
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

 

  if (props.component == "tiposDeSeguros") {
    return (
      <>
       
        <div className="row mb0">
          <div className="" style={{boxShadow: '0 0 4px #cdcdcd'}}>
            <div>
              <div >
                <MenuCore />
              </div>
            </div>
          </div>
        </div>    
        <InsuranceTypes />
        <Footer />
      </>
    );
  }
  else if (props.component == "SabíasQue") {
    return (
      <>
       
        <div className="row mb0">
          <div className="" style={{boxShadow: '0 0 4px #cdcdcd'}}>
            <div>
              <div >
                <MenuCore />
              </div>
            </div>
          </div>
        </div>    
        <News />
        <Footer />
      </>
    );
  }
  else if (props.component == "cotice") {
    return (
      <>
       
        <div className="row mb0">
          <div className="" style={{boxShadow: '0 0 4px #cdcdcd'}}>
            <div>
              <div >
                <MenuCore />
              </div>
            </div>
          </div>
        </div>    
        
        <Footer />
      </>
    );
  }
  else if (props.component == "ComingSoon") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <ComingSoon />
        <Footer />
        <ScrollToTop 
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Courses") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <Courses />
        <Footer />
        <ScrollToTop 
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "GameArtDesign") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <GameArtDesign />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  
  
  else if (props.component == "AdvancedDiplomainTraditionalDigitalConceptArt") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AdvancedDiplomainTraditionalDigitalConceptArt />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  else if (props.component == "DiplomaAndAdvancedDiplomaCourses") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaAndAdvancedDiplomaCourses />
    
        <Footer />
      
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  else if (props.component == "DiplomaAndAdvancedDiplomaCourses2") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaAndAdvancedDiplomaCourses2 />
    
        <Footer />
      
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "AllDiplomaAndAdvancedDiplomaGamingCourses") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AllDiplomaAndAdvancedDiplomaGamingCourses />
    
        <Footer />
      
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }



  else if (props.component == "AllDiplomaAndAdvancedDiplomaGamingCourses") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar style={{background:"#000"}}>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AllDiplomaAndAdvancedDiplomaGamingCourses />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "DiplomaAndAdvancedDiplomaGamingCourses2") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar style={{background:"#000"}}>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaAndAdvancedDiplomaGamingCourses2 />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
 else if (props.component == "DiplomaAndAdvancedDiplomaGamingCourses") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar style={{background:"#000"}}>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaAndAdvancedDiplomaGamingCourses />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  else if (props.component == "MScGameTechnology") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <MScGameTechnology />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "AdvancedDiplomaInGameDevelopment") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AdvancedDiplomaInGameDevelopment />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  

  else if (props.component == "AdvancedDiplomain3DGameArtDigitalSculpting") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AdvancedDiplomain3DGameArtDigitalSculpting />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "DiplomaIn3dEnvironmentArtCreationForGames") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaIn3dEnvironmentArtCreationForGames />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "DiplomainGameDesignProduction") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomainGameDesignProduction />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "DiplomaInGameDevelopmentWithUnity") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <DiplomaInGameDevelopmentWithUnity />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  

  
  
  else if (props.component == "AugmentedRealityAndVirtualReality") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AugmentedRealityAndVirtualReality />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }  
  else if (props.component == "BachelorsInComputerScienceGameDevelopment") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <BachelorsInComputerScienceGameDevelopment />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "AboutUs") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
        <AboutUs />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Blog") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Blog />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Affilication") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Affilication />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Alumni") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Alumni />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "Facilities") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Facilities />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "Accelerator") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Accelerator />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "counter") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <counter />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Datepicker") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Datepicker />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "StudentShowcase") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <StudentShowcase />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component === "Blogdetail") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Blogdetail />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  
  else if (props.component == "Workshop") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Workshop />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Admissions") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Admissions />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "FinancialAssistance") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <FinancialAssistance />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Mentors") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        < Mentors/>
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Scholarships") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Scholarships />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }  
  else if (props.component == "Placements") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Placements />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "ContactUs") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <ContactUs />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }
  else if (props.component == "Applicationform") {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>  
       
        <Applicationform />
        <Footer />
        <ScrollToTop
        smooth
        top={100}
        />
      </>
    );
  }

  else {
    return (
      <>
       
       <React.Fragment>
              <CssBaseline />
              <ThemeProvider theme={darkTheme}>
                <ElevationScroll  {...props}>

                  <AppBar>
                    <Toolbar style={toolbarStyle}>
                      <MenuCore />
                    </Toolbar>
                  </AppBar>

                </ElevationScroll >
              </ThemeProvider>
              <Toolbar />

            </React.Fragment>
        <Main />
        <ScrollToTop 
        smooth
        top={100}
        />
        {/* <a onClick={scrollToTop}>To the top!</a>
    <br/>
    <a onClick={scrollToBottom}>To the bottom!</a>
    <br/>
    <a onClick={scrollTo}>Scroll to 100px from the top</a>
    <br/>
    <a onClick={scrollMore}>Scroll 100px more from the current position!</a> */}


      </>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(menuStyle)(Menu);
